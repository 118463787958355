<template>
  <VCol cols="12">
    <RouterLink
      class="anchor d-flex align-center"
      to="/moderation"
    >
      <VIcon>mdi-chevron-left</VIcon>
      <span>К списку заведений</span>
    </RouterLink>
    <VCol cols="12">
      <span class="page_title">
        {{ moderation.title }}
      </span>
    </VCol>
    <VCol
      v-if="food.length || foodType.length"
      class="d-flex"
      cols="12"
    >
      <span class="before-after medium-title pl-3 mr-auto">Было:</span>
      <span class="before-after medium-title pl-3 mr-auto">Стало:</span>
    </VCol>
    <ModerationComparisonPanels
      :items="foodType"
      type="foodType"
      @moderated="filterItems"
    />
    <ModerationComparisonPanels
      :items="food"
      type="food"
      @moderated="filterItems"
    />
  </VCol>
</template>

<script>
import { serializeModerationRestaurant } from '@/serializers/moderationRestaurantSerializer';
import { fetchModerationRestaurant } from '@/api/api';
import ModerationComparisonPanels from '../components/ModerationComparisonPanels.vue';

export default {
  components: {
    ModerationComparisonPanels,
  },
  data() {
    return {
      moderation: serializeModerationRestaurant({}),
      food: [],
      foodType: [],
    };
  },
  created() {
    fetchModerationRestaurant(this.$route.params.pk).then(
      (result) => {
        this.moderation = result;
        this.sortByType();
      },
    ).catch(
      () => {
        this.rerouteToRestaurantsList();
      },
    );
  },
  methods: {
    filterItems(itemToDelete) {
      this[itemToDelete.type] = this[itemToDelete.type].filter(
        (item) => item.documentId !== itemToDelete.documentId,
      );
    },
    sortByType() {
      this.food = [];
      this.foodType = [];
      const keys = ['food', 'foodType'];
      this.moderation.documents.forEach((document) => {
        keys.forEach((key) => {
          if (document[key]) {
            this[key].push(document);
          }
        });
      });
    },
    rerouteToRestaurantsList() {
      this.$router.push({
        name: 'moderation',
      });
    },
    checkItemsCount() {
      if (!this.food.length && !this.foodType.length) {
        this.rerouteToRestaurantsList();
      }
    },
  },
  watch: {
    food: {
      deep: true,
      handler() {
        this.checkItemsCount();
      },
    },
    foodType: {
      deep: true,
      handler() {
        this.checkItemsCount();
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/page.scss";

.anchor {
  text-decoration: none;
  color: $hover_red_color;
  font-weight: bold;
  i {
    color: $hover_red_color;
  }
}
</style>
