<template>
  <VCol class="d-flex flex-wrap justify-center" cols="12">
    <VBtn
      class="mr-3 px-5 white--text"
      color="#333"
      :disabled="declineDisabled"
      min-width="150"
      rounded
      @click="proceedToDecline"
    >
      Отклонить
    </VBtn>
    <VBtn
      class="ml-3 px-5 white--text"
      color="#4caf50"
      :disabled="isCheckboxDisabled || !checkedFieldsCount"
      min-width="150"
      rounded
      @click="proceedToAccept"
    >
      Принять
    </VBtn>
    <Transition name="appearance">
      <div
        v-if="showComment"
        class="comment d-flex flex-wrap justify-center mt-4 width-100-percent"
      >
        <VCol class="pr-0 pl-3 d-flex flex-wrap align-center" cols="3">
          <span class="input-title">Причина отказа</span>
        </VCol>
        <VCol cols="9">
          <VTextarea
            ref="comment"
            v-model="comment"
            auto-grow
            autofocus
            :label="`Опишите причину ${isPartiallyAccepted ? 'частичного' : ''} отказа`"
            :rules="commentValidation"
            solo
          />
        </VCol>
        <VCol class="d-flex justify-center" cols="12">
          <VBtn
            class="white--text mr-3"
            color="#333"
            min-width="200"
            rounded
            @click="returnToModeration"
          >
            Вернуться к проверке
          </VBtn>
          <VBtn
            class="white--text ml-3"
            color="#4caf50"
            min-width="150"
            rounded
            @click="send"
          >
            Отправить
          </VBtn>
        </VCol>
      </div>
    </Transition>
  </VCol>
</template>

<script>
export default {
  props: {
    isCheckboxDisabled: {
      type: Boolean,
      default: false,
    },
    checkedFieldsCount: {
      type: Number,
      default: 0,
    },
    changedFieldsCount: {
      type: Number,
      default: 0,
    },
  },
  emits: ['accepted', 'declined', 'declineClicked', 'returnToModeration'],
  data() {
    return {
      showComment: false,
      comment: '',
      commentValidation: [
        (text) => text.length > 0 || 'Заполните поле комментария к правкам категории',
      ],
      declineDisabled: false,
    };
  },
  computed: {
    isPartiallyAccepted() {
      return this.checkedFieldsCount < this.changedFieldsCount;
    },
  },
  methods: {
    proceedToAccept() {
      if (this.isPartiallyAccepted) {
        this.showComment = true;
        this.declineDisabled = true;
      } else {
        this.$emit('accepted');
      }
    },
    proceedToDecline() {
      this.showComment = true;
      this.$emit('declineClicked');
    },
    returnToModeration() {
      this.showComment = false;
      this.declineDisabled = false;
      this.$emit('returnToModeration');
    },
    send() {
      if (!this.$refs.comment.validate()) {
        return;
      }
      if (this.isPartiallyAccepted) {
        this.$emit('accepted', this.comment);
        this.declineDisabled = false;
      } else {
        this.$emit('declined', this.comment);
      }
      this.showComment = false;
    },
  },
};
</script>

<style lang="scss" scoped>

.appearance-enter,
.appearance-leave-to {
  transform: rotateX(90deg) scale(0);
  position: absolute;
}

.appearance-enter-active,
.appearance-leave-active {
  transition: transform .4s;
}

</style>
