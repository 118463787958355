<template>
  <VCol class="d-flex align-center flex-wrap" cols="12">
    <VCol class="d-flex align-center pl-2" cols="3">
      <span class="input-title">Описание</span>
    </VCol>
    <VCol class="d-flex align-center" cols="9">
      <VCol class="d-flex align-center" cols="12">
        <VTextarea
          id="description"
          auto-grow
          :disabled="disabled"
          hide-details
          label="Ваш текст"
          solo
          :value="text ? text : ''"
          @change="text = $event"
        />
      </VCol>
    </VCol>
    <VCol
      v-if="showInputDescription"
      class="offset-3"
      cols="8"
    >
      <span class="input-description">
        Описание не должно превышать $ символов. Пример “Сочная курочка в супер-пупер соусе”
      </span>
    </VCol>
  </VCol>
</template>

<script>
export default {
  props: {
    description: {
      type: String,
    },
    showInputDescription: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update'],
  data() {
    return {
      text: this.description ? this.description : '',
    };
  },
  watch: {
    text(text) {
      this.$emit('update', { sellingText: text.trim() });
    },
  },
};

</script>

<style lang="scss" scoped>
@import "src/assets/scss/page.scss";

</style>
