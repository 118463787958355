<template>
  <div>
    <div class="d-flex">
      <div class="checkbox-wrapper">
        <VCheckbox
          v-if="changedFields.includes('title')"
          v-model="checkedFields"
          class="mt-0"
          color="success"
          :disabled="checkboxDisabled"
          hide-details
          :ripple="false"
          value="title"
        />
      </div>
      <NamedRuledTextField
        class="py-0"
        :disabled="disabled || !changedFields.includes('title')"
        :input-width="fieldWidth"
        placeholder="Добавьте название"
        :text="itemToEdit.title"
        title="Название"
        :title-width="titleWidth"
        @update="itemToEdit.title = $event"
      />
    </div>
    <div v-if="type !== 'foodType'" class="d-flex">
      <div class="checkbox-wrapper">
        <VCheckbox
          v-if="changedFields.includes('price')"
          v-model="checkedFields"
          class="mt-0"
          color="success"
          :disabled="checkboxDisabled"
          hide-details
          :ripple="false"
          value="price"
        />
      </div>
      <NamedRuledTextField
        class="py-0"
        :disabled="disabled || !changedFields.includes('price')"
        :input-width="fieldWidth"
        placeholder="Добавьте цену"
        :text="itemToEdit.price"
        title="Цена"
        :title-width="titleWidth"
        @update="itemToEdit.price = $event"
      />
    </div>
    <VCol
      v-if="type !== 'foodType'"
      class="pa-0 d-flex align-center"
      cols="12"
    >
      <div class="checkbox-wrapper">
        <VCheckbox
          v-if="changedFields.includes('logo')"
          v-model="checkedFields"
          class="mt-0 pt-0"
          color="success"
          :disabled="checkboxDisabled"
          hide-details
          :ripple="false"
          value="logo"
        />
      </div>
      <VCol class="pa-0" :cols="titleWidth">
        <span class="input-title">Изображение</span>
      </VCol>
      <VCol :cols="fieldWidth">
        <ImagePreview
          :chosen-image="item.logo || itemToEdit.logo"
          class="py-0"
          :cols="12"
        />
      </VCol>
    </VCol>
    <div v-if="type !== 'foodType'" class="d-flex">
      <div class="checkbox-wrapper d-flex align-center">
        <VCheckbox
          v-if="changedFields.includes('selling_text')"
          v-model="checkedFields"
          class="mb-5"
          color="success"
          :disabled="checkboxDisabled"
          hide-details
          :ripple="false"
          value="sellingText"
        />
      </div>
      <DescriptionText
        class="pa-0"
        :description="itemToEdit.sellingText"
        :disabled="disabled || !changedFields.includes('selling_text')"
        :show-input-description="false"
        @update="itemToEdit.sellingText = $event.sellingText"
      />
    </div>
    <ModerationAcception
      v-if="!isModerated && !isOriginal"
      :changed-fields-count="changedFields.length"
      :checked-fields-count="checkedFields.length"
      :is-checkbox-disabled="checkboxDisabled"
      @accepted="acceptItem"
      @declineClicked="checkboxDisabled = true"
      @declined="declineItem"
      @returnToModeration="checkboxDisabled = false"
    />
  </div>
</template>

<script>
import NamedRuledTextField from '@/components/NamedRuledTextField.vue';
import ImagePreview from '@/components/ImagePreview.vue';
import DescriptionText from '@/components/DescriptionText.vue';
import ModerationAcception from '@/components/ModerationAcception.vue';

export default {
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
    itemCurrent: {
      type: Object,
      default: () => ({}),
    },
    changedFields: {
      type: Array,
      default: () => [],
    },
    isOriginal: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    ImagePreview,
    NamedRuledTextField,
    DescriptionText,
    ModerationAcception,
  },
  emits: ['accepted', 'declined'],
  data() {
    return {
      titleWidth: 3,
      fieldWidth: 8,
      isModerated: false,
      itemToEdit: {
        title: this.item.title || this.itemCurrent.title,
        price: this.item.price || this.itemCurrent.price,
        sellingText: this.item.sellingText || this.itemCurrent.sellingText,
        logo: this.item.logo || this.itemCurrent.logo,
      },
      checkedFields: [],
      checkboxDisabled: false,
    };
  },
  methods: {
    acceptItem(comment = '') {
      const changesLength = Object.keys(this.item).filter(
        (key) => this.itemToEdit[key] !== this.item[key],
      );
      const isPartiallyAccepted = this.checkedFields.length < this.changedFields.length;
      if (changesLength.length > 0) {
        this.$amplitudeEvent('moderation_accept_with_changes');
      } else if (isPartiallyAccepted) {
        this.$amplitudeEvent('moderation_accept_partially');
      } else {
        this.$amplitudeEvent('moderation_accept');
      }
      const item = {
        title: this.itemCurrent.title,
        price: this.itemCurrent.price,
        sellingText: this.itemCurrent.sellingText,
        logo: this.itemCurrent.logo,
      };
      this.checkedFields.forEach((key) => {
        item[key] = this.itemToEdit[key];
      });
      item.description = item.sellingText;
      delete item.sellingText;
      const state = isPartiallyAccepted ? 'rejected' : 'accepted';
      this.$emit('accepted', { item, state, comment });
      this.isModerated = true;
    },
    declineItem(comment) {
      this.$amplitudeEvent('moderation_reject');
      this.isModerated = true;
      this.$emit('declined', comment);
    },
    showButtons() {
      this.isModerated = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/mixin.scss";

.checkbox-wrapper {
  min-width: 24px;
}
</style>
